import { TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";

const HelpCenter = () => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      problem: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/users/send-message`,
          values
        );

        if (response.data.success) {
          toast.success("Your message has been sent!", {
            theme: "dark",
          });
          resetForm();
        } else {
          console.log("Failed to send your message>>>>>>>>>>>>>>>");
          toast.error("Failed to send your message. Please try again later.");
        }
      } catch (error) {
        toast.error("Failed to send your message. Please try again later.");
      } finally {
        // Ensure the loading state is reset
        setLoading(false);
      }
    },
  });

  return (
    <div id="helpcenter" className="text-white">
      <div className="my-12 pt-8">
        <h2 className="text-[56px] font-semibold flex justify-center leading-[50px] mx-2">
          Have a Problem? Let us know!
        </h2>
      </div>
      <div className="pb-10">
        <div className="max-w-[600px] mx-auto p-4 bg-[#2F324190] rounded-xl">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              label="Your Name"
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              margin="normal"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#fff",
                  },
                  "&:hover fieldset": {
                    borderColor: "#ddd",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#ddd",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#fff",
                },
              }}
            />
            <TextField
              fullWidth
              label="Describe Your Problem"
              variant="outlined"
              multiline
              rows={4}
              name="problem"
              value={formik.values.problem}
              onChange={formik.handleChange}
              margin="normal"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#fff",
                  },
                  "&:hover fieldset": {
                    borderColor: "#ddd",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#ddd",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#fff",
                },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{
                backgroundColor: loading ? "#7c7c7c" : "#5235E8",
                "&:hover": {
                  backgroundColor: loading ? "#7c7c7c" : "#3E28B5",
                },
              }}
              className="mt-4"
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
